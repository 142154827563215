export const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

export const VERSION_CONTROL_API = '/api/v1/user/versionControl';
export const HOME_API_LIST = '/api/v8/menu/home';
export const SIGNUP = '/api/v1/user/signUpWithPhone';
export const VERIFY_OTP = '/api/v3/user/verifyOtp';
export const ADD_ADDRESS_API = '/api/v1/user/addAddress';
export const PRODUCT_LIST_API = '/api/v7/product/productListing';
export const ADD_NEW_FEEDBACK_API = '/api/v1/cart/addFeedback';
export const PRODUCT_LIST_API2 = '/api/v2/product/productListing-DL'; // DEEPLINKING
export const ADD_TO_CART_API = '/api/v1/cart/addToCart';
export const GET_CART_ITEM_API = '/api/v1/cart/getCartItems';
export const ADD_TO_REDIS_CART_API = '/api/v3/cart/redisCart';
export const ALTER_REDIS_CART_API = '/api/v2/cart/alterCartItem';
export const GET_ADDRESS_LIST_API = '/api/v1/user/getAddresses';
export const UPDATE_USER_API = '/api/v1/user/updateUser';
export const CLEAR_REDIS_CART_API = '/api/v1/cart/clearRedisCart';
export const GET_PRODUCT_FROM_CART_API = '/api/v2/cart/getProductFromCart';
export const CHECKOUT_CART_API_WEB = '/api/v1/cart/checkOutWeb';
export const ORDER_HISTORY_LIST_API = '/api/v2/cart/getRecentOrdersHead';
export const ORDER_DETAILS_API = '/api/v3/cart/RecentOrderDetail';
export const FEEDBACK_STATUS_RESPONSE_API =
  '/api/v1/cart/getFeedbackStatusResponse';
export const GET_TICKET_STATUS_API = '/api/v1/user/getTicketStatus';
export const FEEDBACK_API_V2_LATEST = '/api/v2/cart/feedback';
export const IS_VIEWED_UPDATE = '/api/v1/cart/updateFeedbackStatus';
export const UPDATE_FEEDBACK = '/api/v1/cart/updateFeedback';
export const GET_CONTROLLERS_API = '/api/v1/orderController/getControllers';
export const DELETE_ADDRESS_API = '/api/v1/user/deleteAddress';
export const GET_SEARCH_SUGGESTIONS_API = '/api/v1/product/searchSuggestions';
export const GET_OUTLETS_API = '/api/v1/outlet/getOutlet';
export const COMBINE_REDIS_CART_API = '/api/v5/cart/combineCart';
export const GET_EDIT_USER_API = '/api/v2/user/editUser';
export const GET_USER_DETAIL_API = '/api/v5/user/getUser';
export const GET_ADDONS_API = '/api/v1/cart/getCravers';
export const GET_MENU_CATEGORY_API = '/api/v3/menu/getcatagory';
export const GET_OFFERS_API = '/api/v1/offers/getOffers';
export const GET_USER_AVAILABLE_POINTS_API =
  '/api/v1/offers/userAvailablePoints';
export const GET_COUPONS_API = '/api/v2/offers/getCoupons';
export const GET_TIPS_LISTING_API = '/api/v1/menu/tipListing';
export const GET_USER_COUPONS_API = '/api/v4/offers/getUserCoupons';
export const GET_PAYMENT_STATUS_WEB_API = '/api/v2/cart/paymentStatusCheck';
export const GET_LEGAL_API = '/api/v1/legal/get/legal';
export const GET_PAYMENT_STATUS_API = '/api/v1/cart/paymentStatusSDK';
export const APPLY_REDIS_COUPON_API = '/api/v5/cart/applyCouponRedis';
export const REMOVE_REDIS__COUPON_API = '/api/v2/cart/redisRemoveCoupon';
export const EARN_FREE_CROWNS_API = '/api/v1/user/earnFreeCrowns';
export const GET_ALL_ADDONS_API = '/api/v4/menu/getaddonlist';
export const LIMITED_DEALS_API = '/api/v4/offers/getLimitedDeals';
export const TOP_DEALS_API = '/api/v1/offers/getTopDeals';
export const DAILY_REWARDS_API = '/api/v1/offers/getDailyRewards';
export const REFRESH_TOKEN_API = '/api/v1/user/refreshToken';
export const CREATE_TOKEN_API = '/api/v2/user/createUserToken'; //test
export const CROWN_HISTORY_API = '/api/v3/offers/getUserCrownsHistory';
export const CHARITY_PRODUCT_API = '/api/v1/menu/charityListing';
export const FEEDBACK_API = '/api/v1/cart/feedback';
export const RESEND_OFFER_OTP_API =
  '/api/v1/employee_discount/resend_offer_otp';
export const EMPLOYEE_VERIFY_OFFER_OTP_API =
  '/api/v1/employee_discount/verify_offer_otp';
export const GET_BKWALL_DATA_API = '/api/v1/outlet/getAppVideo';
export const INVESTOR_RELATIONS_CATEGORY_API =
  '/api/consumerapi/v1/investor/investorTree';
export const INVESTOR_RELATIONS_SUBCATEGORY_API = '/api/v1/investor/catdetail';
export const GET_NUTRITIONAL_INFO = '/api/v1/nutrition/getnutrition';
export const GET_OUTLETS = '/api/v1/cart/getOutletData';
export const SIGNUP_WITH_EMAIL_API = '/api/v1/user/signUpWithEmail';
export const GENERATE_PHONE_OTP_API = '/api/v1/user/generatePhoneOtp';
export const CANCEL_ORDER_API = '/api/v4/cart/cancelOrder'; // stanly ref
export const CART_OFFERS = '/api/v5/offer_views/offerListing';
export const INVESTORTREELIST = 'api/v1/investor/investorTree';
export const REQUESTING_ACCOUNT_DELETE_API = 'api/v1/user/deleteUserRequest';

export const ORGANIZATION_LIST_API = '/api/v1/offers/get/organization';
export const CREATE_CLUB_OFFER_ONBOARDING_API =
  '/api/v1/offers/organization/user';
export const JUSPAY_PAYMENT_METHOD_API = '/api/v2/cart/juspayCodPayment';
